import React from 'react';
import { CircularProgress, LinearProgress } from '@mui/material';

import { PhoneNumberIcon } from 'assets/icons/phoneNumberIcon';
import { ContainerStyled, JustifyCenter } from 'style';
import { useAppSelector } from 'hooks/useAppSelector';
import { MpesaNotification } from 'components/pages/approval/MpessaNotification';
import { usePaymentApproval } from 'components/pages/approval/usePaymentApproval';
import { Card, MarginTop, Timer, Typography } from 'components';
import { PaymentMode } from 'enum/enum';

import { Content, Title } from './styles';

export const PaymentApprovalPage = () => {
  const { paymentTab, paymentBillingInfo } = useAppSelector((state) => state.payment);

  const { isFailed, isLoading, isTimeOut, seconds, retryRequestHandler, changePaymentNumber, setSeconds } = usePaymentApproval();

  const displayError = isFailed && !isTimeOut;
  const isMpesa = paymentTab?.paymentMode === PaymentMode.MPESA_EXPRESS;
  const isVoucher = paymentTab?.paymentMode === PaymentMode.VOUCHER;
  const displayTimer = !isTimeOut && !isFailed && !isMpesa && !isVoucher;

  const commonProps = {
    firstButtonTitle: 'retry',
    secondButtonTitle: 'change number',
    firstButtonFc: retryRequestHandler,
    secondButtonFc: changePaymentNumber,
  };

  const renderContent = () => {
    if (displayError) {
      return <Card {...commonProps} title="Technical error" subtitle="There was a technical error. Please retry the payment" />;
    }

    if (isTimeOut && !isMpesa) {
      return (
        <Card {...commonProps} title="Out of time" subtitle="The charge attempt has expired would you like to retry or change the phone number?" />
      );
    }

    if (isMpesa) {
      return (
        <Card title="Mpesa pay bill" hideFirstButton={true} secondButtonTitle="change number" secondButtonFc={changePaymentNumber}>
          <MpesaNotification paymentBillingInfo={paymentBillingInfo} isFailed={isFailed} />
          {isLoading && <LinearProgress color="primary" />}
        </Card>
      );
    }

    if (isVoucher) {
      return (
        <Card title="Voucher" hideFirstButton={true} hideSecondButton={true}>
          <CircularProgress />
        </Card>
      );
    }
  };

  return (
    <ContainerStyled>
      <Title>
        <Typography variant="titleBold" color="primary">
          Time is of the essence...
        </Typography>
      </Title>
      <Content>{renderContent()}</Content>
      {displayTimer && <Timer setSeconds={setSeconds} seconds={seconds} />}
      <JustifyCenter>
        <MarginTop marginTop="24">
          <PhoneNumberIcon />
        </MarginTop>
      </JustifyCenter>
    </ContainerStyled>
  );
};
