import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentModel, PaymentOption } from 'models/payment.model';
import { Currency } from 'enum/enum';
import { CreatePaymentModel } from 'models/createPayment.model';

interface IInitialState {
  paymentBillingInfo: null | CreatePaymentModel;
  paymentPassengerInfo: PaymentModel | null;
  paymentTab: PaymentOption | null;
  currency: Currency;
  paymentNumber: string;
}

const initialState: IInitialState = {
  paymentBillingInfo: null,
  paymentPassengerInfo: null,
  paymentTab: null,
  currency: Currency.USD,
  paymentNumber: '',
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentBillingInfo(state, action) {
      state.paymentBillingInfo = action.payload;
    },
    setPaymentPassengerInfo(state, action: PayloadAction<PaymentModel | null>) {
      state.paymentPassengerInfo = action.payload;
    },
    setPaymentState(state, action) {
      if (state.paymentBillingInfo) {
        state.paymentBillingInfo.paymentState = action.payload;
      }
    },
    setPaymentCurrency(state, action: PayloadAction<Currency>) {
      state.currency = action.payload;
    },
    setPaymentTab(state, action: PayloadAction<PaymentOption>) {
      state.paymentTab = action.payload;
    },
    setPaymentNumber(state, action: PayloadAction<string>) {
      state.paymentNumber = action.payload;
    },

    resetPaymentState(state) {
      state = initialState;
    },
  },
});
export const {
  setPaymentBillingInfo,
  setPaymentPassengerInfo,
  setPaymentState,
  resetPaymentState,
  setPaymentCurrency,
  setPaymentTab,
  setPaymentNumber,
} = paymentSlice.actions;
