import React from 'react';
import { IconButton } from '@mui/material';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { MarginBottom, Typography } from 'components';

import { PassengerHeader, PassengerInformation, PassengerItemInfo, TypographyAdaptiveBold } from './styles';

type PropTypes = {
  passengerCount: number;
  isBackButton: boolean;
  firstName: string;
  lastName: string;
  pnrEmail: string | null;
  dateOfBirth?: string;
  gender?: string;
  passengerType?: string;
  goBackHandler?: () => void;
};

export const PassengerListItem = (props: PropTypes) => {
  const { passengerCount, isBackButton, goBackHandler, firstName, lastName, pnrEmail, dateOfBirth, gender, passengerType } = props;

  return (
    <>
      <PassengerHeader>
        <Typography variant="smallTitleBold" color="primary">
          Passenger {passengerCount}
        </Typography>
        {isBackButton && (
          <IconButton onClick={goBackHandler} sx={{ color: 'var(--primary)' }}>
            <ModeEditOutlineIcon />
          </IconButton>
        )}
      </PassengerHeader>

      <PassengerItemInfo>
        <Typography variant="subtitleBold" color="gray60">
          Name
        </Typography>
        <TypographyAdaptiveBold>
          {firstName} {lastName}
        </TypographyAdaptiveBold>
      </PassengerItemInfo>

      <PassengerInformation>
        {pnrEmail && (
          <MarginBottom marginBottom="14">
            <PassengerItemInfo>
              <Typography variant="subtitleBold" color="gray60">
                Email
              </Typography>
              <TypographyAdaptiveBold>{pnrEmail}</TypographyAdaptiveBold>
            </PassengerItemInfo>
          </MarginBottom>
        )}
        {passengerType && (
          <MarginBottom marginBottom="14">
            <PassengerItemInfo>
              <Typography variant="subtitleBold" color="gray60">
                Passenger Type
              </Typography>
              <TypographyAdaptiveBold>{passengerType}</TypographyAdaptiveBold>
            </PassengerItemInfo>
          </MarginBottom>
        )}
        {dateOfBirth && (
          <MarginBottom marginBottom="14">
            <PassengerItemInfo>
              <Typography variant="subtitleBold" color="gray60">
                Date of Birth
              </Typography>
              <TypographyAdaptiveBold>{dateOfBirth}</TypographyAdaptiveBold>
            </PassengerItemInfo>
          </MarginBottom>
        )}
        {gender && (
          <MarginBottom marginBottom="14">
            <PassengerItemInfo>
              <Typography variant="subtitleBold" color="gray60">
                Gender
              </Typography>
              <TypographyAdaptiveBold>{gender}</TypographyAdaptiveBold>
            </PassengerItemInfo>
          </MarginBottom>
        )}
      </PassengerInformation>
    </>
  );
};
