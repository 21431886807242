import React from 'react';
import { Box, styled } from '@mui/material';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

const Wrapper = styled(Box)`
  .MuiSvgIcon-root {
    width: 224px;
    height: 224px;
    fill: var(--accent);
  }
`;
export const PhoneNumberIcon = () => {
  return (
    <Wrapper>
      <PhoneInTalkIcon />
    </Wrapper>
  );
};
