import { ListItemButton, styled } from '@mui/material';

export const Image = styled('img')(() => ({
  width: '75px',
}));

export const ListItem = styled(ListItemButton)(() => ({
  borderBottom: `1px solid var(--border)`,
  padding: '20px',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const TitleWithImage = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
}));
