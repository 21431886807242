import React from 'react';
import { Header, Typography } from 'components';
import { AboutPageComponents } from 'components/pages/about/AboutPageComponents';

export const AboutPage = () => {
  const headerContent = (
    <Typography variant="titleBold" color="accent">
      About Us
    </Typography>
  );
  return (
    <>
      <Header content={headerContent} />
      <AboutPageComponents />
    </>
  );
};
