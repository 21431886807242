import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import List from '@mui/material/List';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

import { Typography } from 'components';
import { PaymentNetwork, RoutePage } from 'enum/enum';
import { useToggle } from 'hooks/useToggle';
import { resetMultiPagesAccess } from 'redux/slices/accessability/accessability-slice';
import { Image, ListItem, TitleWithImage } from './styles';

import { WarningBackModal } from './WarningBackModal/WarningBackModal';
import { PaymentOption } from 'models/payment.model';

interface PaymentOptionsProps {
  items: PaymentOption[];
  onClickHandler: (tab: PaymentNetwork) => void;
}

export const PaymentOptions = ({ items, onClickHandler }: PaymentOptionsProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isOpen: warnStepBackModal, toggle: toggleWarnStepBackModal } = useToggle();

  const handleConfirm = () => {
    dispatch(resetMultiPagesAccess([RoutePage.BOOKING, RoutePage.OPTIONS, RoutePage.SUMMARY, RoutePage.SERVICES, RoutePage.PAYMENT_OPTIONS]));
    history.push(RoutePage.HOME);
  };

  useEffect(() => {
    const unblock = history.block((location, action): any => {
      if (action === 'POP') {
        toggleWarnStepBackModal();
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [history]);

  return (
    <div data-testid="payment-options-page">
      <List>
        {items.map(({ img, network, title }) => {
          return (
            <ListItem key={title} onClick={() => onClickHandler(network)}>
              <TitleWithImage>
                <Image src={img} alt="payment-option" />
                <Typography variant="smallTitle" fontWeight={700} color="primary">
                  {title}
                </Typography>
              </TitleWithImage>
              <ArrowForwardIosRoundedIcon color="primary" />
            </ListItem>
          );
        })}
        <WarningBackModal open={warnStepBackModal} onClose={toggleWarnStepBackModal} onConfirm={handleConfirm} />
      </List>
    </div>
  );
};
