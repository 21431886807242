import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { List, ListItem, ListItemText } from '@mui/material';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import { toast } from 'react-toastify';

import { Debugger, InstallPWAButton } from 'components';
import { USER_LOGOUT, USER_LOGOUT_data } from 'api/queries/userLogout';
import { drawerSections } from 'constants/drawerSections';
import { routes } from 'routes';
import { useAppSelector } from 'hooks/useAppSelector';
import { useToggle } from 'hooks/useToggle';
import { isAuthAvailable } from 'constants/auth';
import { getLogos } from 'config/domain/features';
import { useInstallPwaPromptEvent } from 'hooks/useInstallPwaPromptEvent';
import { resetSearchState } from 'redux/slices/search/search-slice';
import { setToken } from 'redux/slices/auth/auth-slice';
import { resetAllPagesAccess } from 'redux/slices/accessability/accessability-slice';
import { resetPriceOfferState } from 'redux/slices/flightOffer/priceOffer-slice';
import { resetBookingState } from 'redux/slices/booking/booking-slice';
import { resetFlightOrderState } from 'redux/slices/flightOrder/flightOrder-slice';
import { resetJourneyState } from 'redux/slices/journeyOption/journeyOption-slice';
import { resetPaymentState } from 'redux/slices/payment/payment-slice';

import { LogoutModal } from './LogoutModal/LogoutModal';
import { DrawerStyled, ListHeaderItem, ListTextItem, LogoWrapper } from './styles';

interface DrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const Drawer = ({ isOpen, setIsOpen }: DrawerProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isLogged = useAppSelector((state) => state.authSlice.token);
  const { isOpen: isOpenLogout, toggle: toggleLogout } = useToggle();

  const [deferredPrompt, setDeferredPrompt] = useInstallPwaPromptEvent();
  const [logout] = useLazyQuery<USER_LOGOUT_data>(USER_LOGOUT);

  const clearValues = () => {
    dispatch(resetAllPagesAccess());
    dispatch(resetBookingState());
    dispatch(resetSearchState());
    dispatch(resetFlightOrderState());
    dispatch(resetJourneyState());
    dispatch(resetPriceOfferState());
    dispatch(resetPaymentState());
    history.push(routes.home);
  };
  const findRouteByRedirectUrl = (redirectUrl: string) => {
    for (const route in routes) {
      if (routes[route] === redirectUrl) {
        return routes[route];
      }
    }
    return '/';
  };

  const onDrawerItemClick = (redirectUrl: string) => {
    const route = findRouteByRedirectUrl(redirectUrl);
    // clear state if client is not at home page
    if (redirectUrl === routes.home && pathname !== route) {
      clearValues();
    }
    history.push(route);
    closeDrawer();
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  const onLogoutHandler = () => {
    dispatch(setToken(null));
    logout();
    toast.success('You have been logged out');
    toggleLogout();
    closeDrawer();
  };
  const logo = getLogos().drawer;
  return (
    <DrawerStyled anchor="left" open={isOpen} onClose={closeDrawer}>
      <LogoWrapper>
        <img src={logo} alt="logo" />
      </LogoWrapper>
      <List>
        {drawerSections.map((section) => (
          <React.Fragment key={section.title}>
            <ListHeaderItem>
              <ListItemText primary={section.title} />
            </ListHeaderItem>
            {section.items.map((item) => (
              <ListItem key={item.sectionTitle} button onClick={() => onDrawerItemClick(item.route)}>
                {item.icon} <ListTextItem primary={item.sectionTitle} />
              </ListItem>
            ))}
          </React.Fragment>
        ))}
        <InstallPWAButton deferredPrompt={deferredPrompt} setDeferredPrompt={setDeferredPrompt} />
        {isAuthAvailable && (
          <ListItem button onClick={isLogged ? toggleLogout : () => onDrawerItemClick(routes.login)}>
            {isLogged ? <LogoutIcon /> : <LoginRoundedIcon />} <ListTextItem primary={isLogged ? 'Logout' : 'Login'} />
          </ListItem>
        )}
        <Debugger />
      </List>

      <LogoutModal open={isOpenLogout} onConfirm={onLogoutHandler} onClose={toggleLogout} />
    </DrawerStyled>
  );
};
