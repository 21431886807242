import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useAppDispatch, useAppSelector } from 'hooks/useAppSelector';
import { PRICE_OFFER, PRICE_OFFER_data, PRICE_OFFER_vars } from 'api/queries/priceOffer';
import { useFlightOrderRequest } from 'hooks/useFlightOrderRequest';
import { setPriceOffer } from 'redux/slices/flightOffer/priceOffer-slice';
import { allowPageAccess } from 'redux/slices/accessability/accessability-slice';
import { Currency, GraphQlRequestError, RoutePage } from 'enum/enum';
import { routes } from 'routes';
import { ActionButtons, ExceptionMessage, Loader, Typography } from 'components';
import { exceptionMessages } from 'constants/exceptionMessages';

import { TotalPrice } from './TotalPrice';
import { useGetUserInfoWithServices } from './useGetUserInfoWithServices';
import { PassengerServiceList } from './PassengerServiceList';
import { useGetSelectedServiceData } from './useSelectServicesContext';
import { ServicesListContainer, ServicesListContent } from './styles';

export const ServicesList = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isLoadingRequest, setLoadingRequest] = useState(false);

  const passengersList = useAppSelector((state) => state.booking.passengers);
  const passengersCount = useAppSelector((state) => state.search.passengers);

  const { loading, flightPrice, journeyUUID, passengersWithGroupedServices, currency, services } = useGetUserInfoWithServices();
  const { selectedIds } = useGetSelectedServiceData();

  const [setPriceOfferRequest, { data: priceOfferData }] = useLazyQuery<PRICE_OFFER_data, PRICE_OFFER_vars>(PRICE_OFFER);
  const createFlightOrderRequest = useFlightOrderRequest(passengersList, passengersCount);
  const hasPriceOfferError =
    priceOfferData?.priceOffer[0]?.__typename === GraphQlRequestError.PRICE_OFFER_TYPE ||
    priceOfferData?.priceOffer[0]?.__typename === GraphQlRequestError.JOURNEY_OFFER_NOT_AVAILABLE;

  const getSelectedServiceObjects = () => {
    const addedServicesMap = new Map();
    const servicesMap = new Map();
    services.forEach((service) => {
      addedServicesMap.set(service.id, {
        serviceOfferId: service.id,
        paxId: service.paxRef,
      });
    });
    services.forEach((service) => {
      servicesMap.set(service.id, { ...service });
    });

    const addedServices = selectedIds.map((id) => addedServicesMap.get(id));
    const selectedServices = selectedIds.map((id) => servicesMap.get(id));
    return { addedServices, selectedServices };
  };

  const addedServices = getSelectedServiceObjects().addedServices;
  const selectedServices = getSelectedServiceObjects().selectedServices;

  const confirmServices = async () => {
    setLoadingRequest(true);

    const flightOffers = await setPriceOfferRequest({
      variables: {
        journeyUUID: journeyUUID as string,
        addedServices: addedServices,
        currency: currency ?? Currency.USD,
      },
    });

    if (
      !flightOffers?.data?.priceOffer?.length ||
      flightOffers.data.priceOffer[0]?.__typename === GraphQlRequestError.PRICE_OFFER_TYPE ||
      flightOffers.data.priceOffer[0]?.__typename === GraphQlRequestError.JOURNEY_OFFER_NOT_AVAILABLE
    ) {
      setLoadingRequest(false);
      return;
    }
    if (flightOffers.data?.priceOffer?.length) {
      dispatch(setPriceOffer(flightOffers!.data.priceOffer[0]));
    }
    await createFlightOrderRequest(flightOffers.data.priceOffer[0]?.id);
    setLoadingRequest(false);
    dispatch(allowPageAccess(RoutePage.PAYMENT_OPTIONS));
    history.push(routes.paymentOptions, { access: true });
  };

  const goBackHandler = () => {
    history.goBack();
  };

  if (isLoadingRequest || loading) {
    return <Loader />;
  }

  return hasPriceOfferError ? (
    <ExceptionMessage goBackHandler={goBackHandler} exceptionText={exceptionMessages.PRICE_OFFER_ISSUE} />
  ) : (
    <ServicesListContainer data-testid="services-page">
      <ServicesListContent>
        <Typography variant="body">Upgrade your journey with our extra services to make your trip even better.</Typography>
        <PassengerServiceList items={passengersWithGroupedServices} />
      </ServicesListContent>
      <div>
        <TotalPrice selectedServices={selectedServices} flightPrice={flightPrice} currency={currency} />
        <ActionButtons submitButtonName="Next: Confirm Services" onSubmit={confirmServices} />
      </div>
    </ServicesListContainer>
  );
};
