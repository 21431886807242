import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Header, Typography } from 'components';
import { ContainerStyled } from 'style';
import { routes } from 'routes';
import { PaymentNetwork, RoutePage } from 'enum/enum';
import { PaymentOptions } from 'components/pages/paymentOptions';
import { allowPageAccess } from 'redux/slices/accessability/accessability-slice';
import { payments } from 'constants/payments';

export const PaymentOptionsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onClickHandler = (network: PaymentNetwork) => {
    const networkInLowerCase = network.toLowerCase();
    dispatch(allowPageAccess(RoutePage.ENTRY));
    history.push(`${routes.entry}/${networkInLowerCase}`, { access: true });
  };

  const headerContent = (
    <Typography variant="titleBold" color="accent">
      Payment Options
    </Typography>
  );
  return (
    <>
      <Header content={headerContent} />
      <ContainerStyled>
        <PaymentOptions items={payments} onClickHandler={onClickHandler} />
      </ContainerStyled>
    </>
  );
};
