import { getCardTypes } from 'constants/payments';
import { PaymentMode } from 'enum/enum';
import { AssociatedPaymentModel } from 'models/associatedPayment.model';
import { isDauusDomain } from 'config/domain/features';
import { images } from 'constants/images';

export const getPaymentCard = (payment: AssociatedPaymentModel) => {
  const includeVoucher = isDauusDomain();
  const { voucher, stripe } = images.payments;
  const foundCard = getCardTypes(includeVoucher)?.filter((el) => el?.cardBrand === payment?.cardBrand)[0];
  if (foundCard) return foundCard;
  if (payment?.paymentMode === PaymentMode.VOUCHER) {
    return {
      cardBrand: PaymentMode.VOUCHER,
      img: voucher,
    };
  }
  return {
    cardBrand: PaymentMode.STRIPE,
    img: stripe,
  };
};

export const getConfirmationNumber = (payment: AssociatedPaymentModel) => {
  if (payment?.networkConfirmationCode) return payment?.networkConfirmationCode;
  return `${payment?.paymentMode}${payment?.paymentId.slice(-4)}`;
};
