import { styled } from '@mui/material';

export const DateChangeIndicator = styled('div')(() => ({
  padding: '2px 6px',
  backgroundColor: 'var(--lightYellow)',
  borderRadius: '20px',
  width: 'fit-content',
  marginLeft: 'auto',
  marginBottom: '6px',
}));
