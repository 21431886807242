import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { ContainerStyled } from 'style';
import { Header, Typography } from 'components';
import {
  StyledBoxButtonWrap,
  StyledBoxColInner,
  StyledBoxContainer,
  StyledBoxInfo,
  StyledBoxMain,
  StyledBoxRow,
  StyledLinkHome,
  StyledTypographyDescription,
  StyledBoxImageWrapper,
  StyledTypographyTitle,
  StyledBoxImageInner,
  StyledBoxImageCol,
  StyledBoxImageShapeContainer,
  StyledBoxImageInnerContainer,
  StyledLazyLoadImage,
} from './components';

export const PageNotFound = () => {
  const headerContent = (
    <Typography variant="titleBold" color="accent">
      Opps!
    </Typography>
  );

  return (
    <>
      <Header content={headerContent} />
      <ContainerStyled>
        <StyledBoxMain>
          <StyledBoxContainer>
            <StyledBoxRow>
              <StyledBoxInfo>
                <StyledBoxColInner>
                  <Box>
                    <StyledTypographyTitle variant="h1"> 404 </StyledTypographyTitle>
                    <StyledTypographyDescription variant="h6">
                      Oops! Looks like you followed a bad link.
                      <br />
                      If you think this is a problem with us, please{' '}
                      <Link href={''} underline="none">
                        tell us
                      </Link>
                    </StyledTypographyDescription>
                    <StyledBoxButtonWrap>
                      <StyledLinkHome component={Link} href={'/'} variant="contained">
                        Back home
                      </StyledLinkHome>
                    </StyledBoxButtonWrap>
                  </Box>
                </StyledBoxColInner>
              </StyledBoxInfo>
              <StyledBoxImageWrapper>
                <StyledBoxImageInner>
                  <StyledBoxImageCol>
                    <StyledBoxImageShapeContainer>
                      <StyledBoxImageInnerContainer>
                        <StyledLazyLoadImage component={LazyLoadImage} effect="blur" src={'https://blog.fasax.com/uploads/mgq-ocean-wing_01.jpg'} />
                      </StyledBoxImageInnerContainer>
                    </StyledBoxImageShapeContainer>
                  </StyledBoxImageCol>
                </StyledBoxImageInner>
              </StyledBoxImageWrapper>
            </StyledBoxRow>
          </StyledBoxContainer>
        </StyledBoxMain>
      </ContainerStyled>
    </>
  );
};
