import React from 'react';
import { Header, Typography } from 'components';

export const BookingHeader = () => {
  const headerContent = (
    <>
      <Typography variant="titleBold" color="accent">
        Passengers
      </Typography>
    </>
  );
  return <Header content={headerContent} />;
};
