import { domains } from 'constants/domains';
import { images } from 'constants/images';

const matchesWildcard = (hostname: string, domain: string) => {
  const regex = new RegExp('^' + domain.replace(/\*/g, '.*') + '$');
  return regex.test(hostname);
};

export const isDauusDomain = () => {
  const hostname = window.location.hostname;
  return domains.default.some((domain) => matchesWildcard(hostname, domain));
};

export const getLogos = () => {
  const currentDomain = window.location.href;
  let domainType = 'fasax';

  for (const [key, values] of Object.entries(domains)) {
    if (values.some((domain) => currentDomain.includes(domain))) {
      domainType = key;
      break;
    }
  }

  switch (domainType) {
    case 'ke':
      return {
        header: images.domains.ke.header,
        drawer: images.domains.ke.drawer,
        service: images.domains.ke.service,
      };
    case 'fasax':
    default:
      return {
        header: images.domains.fasax.header,
        drawer: images.domains.fasax.drawer,
        service: images.domains.fasax.service,
      };
  }
};
