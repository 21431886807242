import { Container, Link, styled } from '@mui/material';

export const FooterContainer = styled('footer')`
  position: absolute;
  width: 100%;
  padding: 28px 0 12px;
  height: 116px;
  background-color: var(--primaryDark);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const FooterLinks = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LinkFooterStyled = styled(Link)`
  &:hover svg {
    transition: 0.2s ease-in-out;
    fill: var(--white);
  }
`;
