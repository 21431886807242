import { ListItem, Paper, styled } from '@mui/material';

export const PassengersDropdown = styled(Paper)`
  padding: 16px;
  position: absolute !important;
  top: 56px;
  right: 0;
  z-index: 9;
  min-width: 500px;
  border: 1px solid var(--border);
  @media (max-width: 768px) {
    width: 100%;
    min-width: unset;
  }
`;

export const ListItemStyled = styled(ListItem)`
  border-bottom: 1px solid var(--gray10);
  gap: 8px;
  & .MuiButtonGroup-root {
    display: flex;
    align-items: center;
  }
  & .MuiListItemText-secondary {
    font-size: 16px;
  }

  & .MuiListItemText-root {
    flex: unset;
  }
`;

export const DoneButtonBlock = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;
