import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ActionButtons, AutocompleteInput, CustomSelectDatePicker, Input, InputPhone, MarginTop } from 'components';
import { Typography } from '@mui/material';
import { FieldError, SubmitErrorHandler, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { countYearsAgeByDate, padWithLeadingZero } from 'utils/dates';
import { regex } from 'validation/regex';
import { errorMessages } from 'validation/errorMessages';
import { routes } from 'routes';
import { setBookingPassengersValues } from 'redux/slices/booking/booking-slice';
import { setPaymentPassengerInfo } from 'redux/slices/payment/payment-slice';
import { PassengerModel } from 'models/passenger.model';
import { useAppSelector } from 'hooks/useAppSelector';
import { allowPageAccess, resetPageAccess } from 'redux/slices/accessability/accessability-slice';
import { resetJourneyOptionDetails } from 'redux/slices/journeyOption/journeyOption-slice';
import { PassengerType, RoutePage } from 'enum/enum';

import { defaultValues } from './defaultValues';
import { genderOptions, INDEPENDENT_ADULT_AGE } from './constants';
import { useBookingFormData } from './useBookingFormData';
import nationality from './nationality.json';
import { schema } from './schema';
import { BookingFormHeader } from './BookingFormHeader';
import { findErrorElement, focusOnFirstInvalidField, handlePassengerErrors } from './utils';
import { isDauusDomain } from 'config/domain/features';

interface BookFlightFormProps {
  passengersCount: number;
}

interface BookFlightFormValues {
  passengers: PassengerModel[];
  pnrEmail: string;
  pnrPhoneNumber: string;
}

export const BookFlightForm = ({ passengersCount }: BookFlightFormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isDauus = isDauusDomain();

  const { passengers } = useAppSelector((state) => state.booking);
  const { paymentPassengerInfo } = useAppSelector((state) => state.payment);
  const { passengers: passengersCountData } = useAppSelector((state) => state.search);
  const departureDate = useAppSelector((state) => state.journeyOption.journeyOption?.departureDate);

  const startDateOfBirth = new Date(departureDate as string);
  const passengersInfo = useBookingFormData(passengersCountData);

  const defaultPersistentValues = {
    passengers: passengers,
    pnrPhoneNumber: paymentPassengerInfo?.pnrPhoneNumber,
    pnrEmail: paymentPassengerInfo?.pnrEmail,
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
    getValues,
    watch,
  } = useForm<BookFlightFormValues>({
    defaultValues: passengers?.length ? defaultPersistentValues : defaultValues(passengersCount),
    resolver: yupResolver(schema(isDauus)),
  });
  const { fields } = useFieldArray({
    control,
    name: 'passengers',
  });

  const hasValidationIndependentPassed = (passengers: PassengerModel[]): boolean => {
    const fullYearsAgePassengers = passengers.map((p) => countYearsAgeByDate(p.dateOfBirth, startDateOfBirth));
    const isAnyoneIndependent = !!fullYearsAgePassengers.find((passYear) => passYear >= INDEPENDENT_ADULT_AGE);
    if (!isAnyoneIndependent) {
      passengers.forEach((p, i) => {
        if (p.passengerType === PassengerType.ADULT) {
          setError(`passengers.${i}.birthYear`, { message: errorMessages.independentPassengerAge });
        }
      });
      return false;
    }
    return true;
  };

  const onSubmit = (values: BookFlightFormValues) => {
    const bookedPassengers = values.passengers.map((passenger: PassengerModel, i) => {
      const day = padWithLeadingZero(passenger.birthDay as string);
      const month = padWithLeadingZero(passenger.birthMonth as string);
      const dateOfBirth = `${passenger.birthYear}-${month}-${day}`;
      return {
        ...passenger,
        dateOfBirth: dateOfBirth,
        passengerType: passengersInfo[i].queryType,
        ...(isDauus ? { passportNumber: passenger.passportNumber || 'NA123456' } : {}),
      };
    });
    const isValid = hasValidationIndependentPassed(bookedPassengers);
    if (!isValid) return; // if validation did not pass - we'll not go forward

    dispatch(setBookingPassengersValues(bookedPassengers));
    dispatch(
      setPaymentPassengerInfo({
        pnrEmail: values.pnrEmail,
        pnrPhoneNumber: regex.parsePhoneNumber(values.pnrPhoneNumber),
      }),
    );
    dispatch(allowPageAccess(RoutePage.SUMMARY));
    history.push(routes.summary, { access: true });
  };

  const onBack = () => {
    dispatch(resetJourneyOptionDetails());
    dispatch(resetPageAccess(RoutePage.BOOKING));
    dispatch(setPaymentPassengerInfo(null));
    dispatch(setBookingPassengersValues([]));
  };
  const onError: SubmitErrorHandler<BookFlightFormValues> = (errors: any) => {
    // find the first error by order in jsx, focus the field and moves screen to error
    const fieldOrder: (keyof BookFlightFormValues)[] = ['pnrPhoneNumber', 'pnrEmail', 'passengers'];

    for (const field of fieldOrder) {
      if (!errors[field]) continue;

      if (field === 'passengers') {
        const passengerErrors = errors.passengers;
        if (passengerErrors && passengerErrors.length > 0) {
          handlePassengerErrors(passengerErrors);
          return;
        }
      } else {
        const errorElement = findErrorElement(field);
        if (errorElement) {
          focusOnFirstInvalidField(errorElement);
          return;
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete={'off'}>
      <BookingFormHeader title={'Contact Information'} />

      <MarginTop marginTop={'12'}>
        <Typography fontSize={18} lineHeight={'21px'} fontWeight={300}>
          Please enter passenger details:
        </Typography>
      </MarginTop>

      <MarginTop marginTop={'12'}>
        <InputPhone error={errors?.pnrPhoneNumber} control={control} name={`pnrPhoneNumber`} label={'Phone Number*'} />
      </MarginTop>

      <MarginTop marginTop={'12'}>
        <Input error={errors?.pnrEmail} control={control} name={`pnrEmail`} label={'Email*'} />
      </MarginTop>

      {fields.map((passengerFields, i) => {
        const selectedBirthYear = getValues().passengers[i].birthYear;
        const selectedBirthMonth = getValues().passengers[i].birthMonth;
        const selectedBirthDay = getValues().passengers[i].birthDay;
        return (
          <React.Fragment key={passengerFields.id}>
            <MarginTop marginTop={'12'}>
              <BookingFormHeader title={passengersInfo[i].title} />
            </MarginTop>
            <MarginTop marginTop={'12'}>
              <Input error={errors?.passengers?.[i]?.firstName} control={control} name={`passengers.${i}.firstName`} label={'First name*'} />
            </MarginTop>
            <MarginTop marginTop={'12'}>
              <Input error={errors?.passengers?.[i]?.middleName} control={control} name={`passengers.${i}.middleName`} label={'Middle name'} />
            </MarginTop>
            <MarginTop marginTop={'12'}>
              <Input error={errors?.passengers?.[i]?.lastName} control={control} name={`passengers.${i}.lastName`} label={'Last name*'} />
            </MarginTop>
            <MarginTop marginTop={'12'}>
              <AutocompleteInput
                error={errors?.passengers?.[i]?.gender as FieldError}
                control={control}
                name={`passengers.${i}.gender`}
                label={'Gender*'}
                options={genderOptions}
              />
            </MarginTop>
            <MarginTop marginTop={'12'}>
              <CustomSelectDatePicker
                control={control}
                watch={watch}
                yearName={`passengers.${i}.birthYear`}
                monthName={`passengers.${i}.birthMonth`}
                daysName={`passengers.${i}.birthDay`}
                selectedYear={Number(selectedBirthYear) ?? 0}
                selectedMonth={Number(selectedBirthMonth) ?? 0}
                selectedDay={Number(selectedBirthDay) ?? 0}
                setValue={setValue}
                limit={{ min: passengersInfo[i].min, max: passengersInfo[i].max }}
                startDateOfBirth={startDateOfBirth as Date}
                yearError={errors?.passengers?.[i]?.birthYear}
                monthError={errors?.passengers?.[i]?.birthMonth}
                dayError={errors?.passengers?.[i]?.birthDay}
              />
            </MarginTop>
            <MarginTop marginTop={'12'}>
              <AutocompleteInput
                error={errors?.passengers?.[i]?.nationality as FieldError}
                control={control}
                name={`passengers.${i}.nationality`}
                label={'Nationality*'}
                options={nationality}
              />
            </MarginTop>
            <MarginTop marginTop={'12'}>
              <Input
                error={errors?.passengers?.[i]?.passportNumber}
                control={control}
                name={`passengers.${i}.passportNumber`}
                label={`Passport number${isDauus ? '' : '*'}`}
              />
            </MarginTop>
          </React.Fragment>
        );
      })}

      <MarginTop marginTop={'30'}>
        <ActionButtons submitButtonName="Next: Confirm Booking" onBack={onBack} />
      </MarginTop>
    </form>
  );
};
