import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    return toast.error(action.error.message, {
      position: 'bottom-center',
      toastId: 'errorId',
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      icon: undefined,
      progress: undefined,
      theme: 'colored',
      autoClose: 3000,
      style: {
        backgroundColor: 'var(--error)',
      },
    });
  }

  return next(action);
};
