import React from 'react';
import { Header, Typography } from 'components';
import { ContainerStyled } from 'style';
import { Login } from 'components/pages/login/Login';

export const LoginPage = () => {
  const headerContent = (
    <Typography variant="titleBold" color="accent">
      Login
    </Typography>
  );

  return (
    <>
      <Header content={headerContent} />
      <ContainerStyled>
        <Login />
      </ContainerStyled>
    </>
  );
};
