import React, { useEffect } from 'react';
import { Control, FieldError, Path } from 'react-hook-form';
import { PathValue } from 'react-hook-form/dist/types/utils';
import { UnpackNestedValue, UseFormSetValue, UseFormWatch } from 'react-hook-form/dist/types/form';

import { Select, Typography } from 'components';
import { DatepickerLimitModel } from 'models/datepicker-limit.model';

import { Container, LabelContainer } from './styles';
import { generateDays, GeneratedOption, generateMonths, generateYears } from './utils';

/**
 * A custom date picker component.
 *
 * @param {control} - Need to pass through component to track the form values
 * @param {watch} - Need to check updates for yearName, monthName to days/month based on age limits
 * @param {yearName, monthName, daysName} - Needs to be passed to component, these are names which are using in form react-hook-form
 * @param {selectedYear, selectedMonth, selectedDay } - Need to pass values of these fields to generate years/month/days according age limits
 * @param {setValue} - Function which needs to be passed from form to component, in order to set values from select to the form
 * @param {startDateOfBirth} - The date, from which we will generate years/month/days limits. I.E we pass future date like departure date, in order to validate ages in the time, when trip will happen.
 * We can pass the current Date (new Date()) - in this case custom date picker will generate data from current day, so it can be reusable for an ordinary date picker.
 * @param {yearError, monthError, dayError} - Passing error object to highlight error in select and error text
 */

interface CustomSelectDatePickerProps<T> {
  control: Control<T>;
  watch: UseFormWatch<T>;
  yearName: string;
  monthName: string;
  daysName: string;
  selectedYear: number;
  selectedMonth: number;
  selectedDay: number;
  setValue: UseFormSetValue<T>;
  startDateOfBirth: Date;
  yearError: FieldError | undefined;
  monthError: FieldError | undefined;
  dayError: FieldError | undefined;
}

interface LimitProps {
  limit: DatepickerLimitModel;
}

export const CustomSelectDatePicker = <T,>({
  control,
  watch,
  yearName,
  monthName,
  daysName,
  selectedYear,
  selectedMonth,
  selectedDay,
  setValue,
  limit,
  startDateOfBirth,
  yearError,
  monthError,
  dayError,
}: CustomSelectDatePickerProps<T> & LimitProps) => {
  watch([yearName as Path<T>, monthName as Path<T>, daysName as Path<T>]);

  const minBirthYear = startDateOfBirth.getFullYear() - limit.max;
  const maxBirthYear = startDateOfBirth.getFullYear() - limit.min;

  // Generate arrays for years, months, and days using utility functions
  const years = generateYears(minBirthYear, maxBirthYear);
  const months = generateMonths(startDateOfBirth, selectedYear, limit.min, limit.max);
  const days = generateDays(startDateOfBirth, selectedYear, selectedMonth, limit.min, limit.max);

  // common function to clear month/days values, if month do not exist in current year based on limits, or if days are not exist in month
  const onClearValue = (fieldName: string, selectedValue: number, options: GeneratedOption[]) => {
    const isExist = options.find((v) => v.value === selectedValue);
    if (!isExist) {
      setValue(fieldName as Path<T>, '' as UnpackNestedValue<UnpackNestedValue<PathValue<T, Path<T>>>>);
    }
  };
  const onClearMonth = () => {
    onClearValue(monthName as Path<T>, selectedMonth, months);
  };

  const onClearDay = () => {
    onClearValue(daysName as Path<T>, selectedDay, days);
  };

  useEffect(() => {
    onClearMonth();
    onClearDay();
  }, [selectedYear, selectedMonth]);

  return (
    <>
      <LabelContainer>
        <Typography color="label" variant="subtitle">
          Date of birth
        </Typography>
      </LabelContainer>

      <Container>
        <Select
          onChange={(_) => {
            setValue(yearName as Path<T>, selectedYear as UnpackNestedValue<PathValue<T, Path<T>>>);
          }}
          label="Year*"
          fullWidth
          control={control}
          name={yearName}
          options={years}
          error={yearError}
        />
        <Select
          onChange={(_) => setValue(monthName as Path<T>, selectedMonth as UnpackNestedValue<PathValue<T, Path<T>>>)}
          label="Month*"
          fullWidth
          control={control}
          name={monthName}
          options={months}
          error={monthError}
        />
        <Select
          onChange={(_) => setValue(daysName as Path<T>, selectedDay as UnpackNestedValue<PathValue<T, Path<T>>>)}
          label="Day*"
          fullWidth
          control={control}
          name={daysName}
          options={days}
          error={dayError}
        />
      </Container>
    </>
  );
};
