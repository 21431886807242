import React from 'react';
import { Header, Typography } from 'components';
import { ServicesList } from 'components/pages/services';
import { ContainerStyled } from 'style';
import { SelectedIdsProvider } from 'components/pages/services/useSelectServicesContext';

export const ServicesPage = () => {
  const headerContent = (
    <Typography variant="titleBold" color="accent">
      Services
    </Typography>
  );
  return (
    <>
      <Header content={headerContent} />
      <ContainerStyled>
        <SelectedIdsProvider>
          <ServicesList />
        </SelectedIdsProvider>
      </ContainerStyled>
    </>
  );
};
