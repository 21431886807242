import { styled, Typography } from '@mui/material';

export const TypographyAdaptiveBold = styled(Typography)`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
`;

export const PassengerInformation = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 12px;
`;

export const PassengerHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const PassengerItemInfo = styled('div')`
  display: flex;
  flex-direction: column;
`;
