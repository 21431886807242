import React from 'react';
import { Header, Typography } from 'components';
import { PaymentApprovalPage as PaymentApprovalPageComponent } from 'components/pages/approval';

export const PaymentApprovalPage = () => {
  const headerContent = (
    <Typography variant="titleBold" color="accent">
      Payment Approval
    </Typography>
  );
  return (
    <>
      <Header content={headerContent} />
      <PaymentApprovalPageComponent />
    </>
  );
};
