import React from 'react';
import { Header, Typography } from 'components';
import { MyTripPageComponent } from 'components/pages/mytrips/MyTripPageComponent';

export const MyTripsPage = () => {
  const headerContent = (
    <Typography variant="titleBold" color="accent">
      My Trips
    </Typography>
  );

  return (
    <>
      <Header content={headerContent} />
      <MyTripPageComponent />
    </>
  );
};
