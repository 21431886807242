import React from 'react';
import { BoxSpaceBetween } from 'style';
import { Typography } from 'components';

interface IProps {
  title: string;
}

export const BookingFormHeader = ({ title }: IProps) => {
  return (
    <BoxSpaceBetween sx={{ height: '36px' }}>
      <Typography variant="smallTitleBold" fontWeight={700} color={'primary'}>
        {title}
      </Typography>
      <Typography variant="body" color={'gray80'} sx={{ alignSelf: 'end' }}>
        *Required
      </Typography>
    </BoxSpaceBetween>
  );
};
