import React from 'react';
import { Header, Typography } from 'components';
import { PrivacyTermsContent } from 'components/pages/privacy-terms';

export const PrivacyTerms = () => {
  const headerContent = (
    <Typography variant="titleBold" color="accent">
      Privacy & Terms
    </Typography>
  );

  return (
    <>
      <Header content={headerContent} />
      <PrivacyTermsContent />
    </>
  );
};
