import React from 'react';
import { ContainerStyled } from 'style';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { PrivacyContactCard } from './PrivacyContactCard';
import { PrivacyContent } from './PrivacyContent';
import { getLogos } from 'config/domain/features';

export const PrivacyTermsContent = () => {
  const theme = useTheme();
  const logo = getLogos().service;
  return (
    <ContainerStyled>
      <Box boxShadow={4} borderRadius={2}>
        <Box bgcolor={theme.palette.primary.main} borderRadius={2}>
          <Box maxWidth={{ sm: 720, md: 1236 }} width={1} margin={'0 auto'} paddingY={{ xs: 2, sm: 3, md: 5 }} paddingX={{ xs: 2, sm: 4 }}>
            <Typography
              variant={'h5'}
              gutterBottom
              sx={{
                fontWeight: 700,
                color: theme.palette.common.white,
              }}
            >
              Company terms & privacy policy
            </Typography>
            <Typography
              gutterBottom
              sx={{
                color: theme.palette.common.white,
              }}
            >
              Last modified on <strong>Sep 12th, 2022</strong>
            </Typography>
          </Box>
          <Box width={1} marginBottom={-1} bgcolor="secondary.dark">
            <img src={logo} alt="logo" />
          </Box>
        </Box>
        <Box
          maxWidth={{ sm: 720, md: 1236 }}
          width={1}
          margin={'0 auto'}
          paddingY={{ xs: 4, sm: 6, md: 8 }}
          paddingTop={'0 !important'}
          paddingX={{ xs: 2, sm: 4 }}
          position={'relative'}
          top={0}
        >
          <Box component={Grid} container spacing={4} flexDirection={{ xs: 'column-reverse', md: 'row' }}>
            <Grid item xs={12} md={9}>
              <PrivacyContent />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box position={'sticky'} top={theme.spacing(10)} className={'sticky'}>
                <PrivacyContactCard />
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
    </ContainerStyled>
  );
};
