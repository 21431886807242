import React from 'react';
import { Header, Typography } from 'components';
import { Blog } from 'components/pages/blog';

export const BlogPage = () => {
  const headerContent = (
    <Typography variant="titleBold" color="accent">
      Blog
    </Typography>
  );

  return (
    <>
      <Header content={headerContent} />
      <Blog />
    </>
  );
};
