import React from 'react';
import { ContainerStyled } from 'style';
import { MyTripsForm } from 'components/pages/mytrips/MyTripsForm/MyTripsForm';
import { getLogos } from 'config/domain/features';

import { ImageWrapper, MyTripWrapper } from './styles';

export const MyTripPageComponent = () => {
  const logo = getLogos().service;
  return (
    <ContainerStyled>
      <MyTripWrapper>
        <ImageWrapper>
          <img src={logo} alt="fasax-logo" />
        </ImageWrapper>
        <div>
          <MyTripsForm />
        </div>
      </MyTripWrapper>
    </ContainerStyled>
  );
};
