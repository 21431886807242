import React from 'react';
import { images } from 'constants/images';
import { CompaniesContainer } from './styles';

export const CompanyPictures = () => {
  const { edahab, telesom, evcplus, golis } = images.payments;

  return (
    <CompaniesContainer>
      <img src={evcplus} alt="evc logo" />
      <img src={golis} alt="golis logo" />
      <img src={edahab} alt="dahab logo" />
      <img src={telesom} alt="telesom logo" />
    </CompaniesContainer>
  );
};
