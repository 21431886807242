import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FieldError, useFieldArray, useForm } from 'react-hook-form';
import { ErrorOption } from 'react-hook-form/dist/types/errors';
import { yupResolver } from '@hookform/resolvers/yup';
import { cloudflareClient } from 'cloudflare/client';
import { useQuery } from '@apollo/client';
import {
  Add as AddIcon,
  CompareArrows as CompareArrowsIcon,
  DeleteForever as DeleteForeverIcon,
  FlightLand as FlightLandIcon,
  FlightTakeoff as FlightTakeoffIcon,
} from '@mui/icons-material';
import Button from '@mui/material/Button';
import { Box, IconButton } from '@mui/material';

import { AIRPORTS, AIRPORTS_data, AIRPORTS_vars } from 'api/queries/airports';
import { MarginTop } from 'components';
import { AutocompleteAirport } from 'components/pages/home/AutocompleteAirport';
import { PassengersCountDrawer } from 'components/pages/home/PassengersCountDrawer';
import { SelectHome } from 'components/pages/home/SelectHome';
import { SelectionDates } from 'components/pages/home/SelectionDate/SelectionDate';
import { Currency, DirectionEnum, RoutePage } from 'enum/enum';
import { useAppSelector } from 'hooks/useAppSelector';
import { useDebounce } from 'hooks/useDebounce';
import { PassengerCountModel } from 'models/passenger.model';
import { SearchModel } from 'models/search.model';
import { setAmadeus, setOrchestra, setSearchFormValues } from 'redux/slices/search/search-slice';
import { allowOptionsAccess, allowPageAccess } from 'redux/slices/accessability/accessability-slice';
import { setPaymentCurrency } from 'redux/slices/payment/payment-slice';
import { convertShortDate } from 'utils/dates';
import { BoxSpaceBetween, ButtonBlock } from 'style';

import { creatingRouterLinkForTrip } from './helper';
import { directionOptions, ticketClassOptions } from './constans';
import { schema } from './schema';
import { AddFlightBlock, AddFlightButton, ChangeWayButton, SelectionDateBlock } from './style';

interface Props {
  selectedCurrency: Currency;
}

export const FlightSearchForm = ({ selectedCurrency }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [airportSearch, setAirportSearch] = useState<string>('');

  const search = useAppSelector((state) => state.search);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<SearchModel>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: search,
  });
  watch(['direction', 'ItineraryRequest']);

  const { isDebug, isOrchestra, isAmadeus } = useAppSelector((state) => state.debugger);
  const { fuzzyLevel } = useAppSelector((state) => state.debugger);
  const airportsSearch = useDebounce(airportSearch, 500);

  const { data: airportsData, loading } = useQuery<AIRPORTS_data, AIRPORTS_vars>(AIRPORTS, {
    variables: {
      search: airportsSearch,
      threshold: fuzzyLevel,
    },
    skip: !airportsSearch,
    client: cloudflareClient,
    fetchPolicy: 'no-cache',
  });

  const direction = getValues('direction');
  const isRound = direction === DirectionEnum.ROUND;
  const isMultiCity = direction === DirectionEnum.MULTI;

  const filteredDirectionOptions = useMemo(() => {
    if (!isDebug) {
      return directionOptions.filter((item) => item.value !== DirectionEnum.MULTI);
    }
    return directionOptions;
  }, [isDebug]);

  const {
    fields: itineraryRequest,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'ItineraryRequest',
  });

  const onAirportSearch = (search: string) => {
    setAirportSearch(search);
  };

  const resetReturnDateForNotRound = () => {
    const itineraryLength = getValues('ItineraryRequest').length;
    if (!isRound) {
      for (let i = 0; i < itineraryLength; i++) {
        setValue(`ItineraryRequest.${i}.returnDate`, null);
      }
    }
  };

  const onSubmit = (values: SearchModel) => {
    dispatch(allowPageAccess(RoutePage.OPTIONS));
    dispatch(allowOptionsAccess());
    dispatch(setSearchFormValues(values));
    if (values.direction !== DirectionEnum.ROUND) {
      resetReturnDateForNotRound();
    }
    dispatch(setPaymentCurrency(selectedCurrency));
    history.push(`/options/${creatingRouterLinkForTrip(values)}`);
  };

  const handlePassengers = (updatedPassengers: PassengerCountModel) => {
    setValue('passengers', updatedPassengers);
  };

  const handleChangeWays = (index: number) => {
    const currentItinerary = getValues(`ItineraryRequest.${index}`);
    if (!currentItinerary.originAirport && !currentItinerary.destinationAirport) return;
    const temp = { ...currentItinerary };
    const temp2 = { ...currentItinerary };
    temp2.originAirport = temp.destinationAirport;
    temp2.destinationAirport = temp.originAirport;
    setValue(`ItineraryRequest.${index}.destinationAirport`, temp2.destinationAirport);
    setValue(`ItineraryRequest.${index}.originAirport`, temp2.originAirport);

    if (errors?.ItineraryRequest?.[index]?.originAirport && errors?.ItineraryRequest?.[index]?.destinationAirport) {
      return;
    }

    if (errors?.ItineraryRequest?.[index]?.originAirport) {
      setError(`ItineraryRequest.${index}.destinationAirport`, errors?.ItineraryRequest?.[index]?.originAirport as ErrorOption);
      clearErrors(`ItineraryRequest.${index}.originAirport`);
      return;
    }
    if (errors?.ItineraryRequest?.[index]?.destinationAirport) {
      setError(`ItineraryRequest.${index}.originAirport`, errors?.ItineraryRequest?.[index]?.destinationAirport as ErrorOption);
      clearErrors(`ItineraryRequest.${index}.destinationAirport`);
      return;
    }
  };

  const addAnotherItinerary = () => {
    const prevItinerary = getValues(`ItineraryRequest.${itineraryRequest.length - 1}`);

    append({
      departureDate: null,
      originAirport: prevItinerary?.destinationAirport,
      destinationAirport: null,
      includeDaysAfter: 0,
      includeDaysBefore: 0,
    });
  };

  const onDirectionChange = () => {
    const ItineraryRequest = getValues('ItineraryRequest');
    const updatedItineraries = ItineraryRequest.slice(0, 1);
    setValue('ItineraryRequest', updatedItineraries);
  };

  const getFormattedDateSelection = (departureDate: Date | null, returnDate?: Date | null) => {
    let formattedInputDate = '';
    if (departureDate && isRound) {
      const _returnDate = returnDate ? `- ${convertShortDate(returnDate)}` : '';
      formattedInputDate = `${convertShortDate(departureDate)} ${_returnDate}`;
    }
    if (departureDate && !isRound) {
      formattedInputDate = convertShortDate(departureDate);
    }
    return formattedInputDate;
  };
  const itineraries = isMultiCity ? itineraryRequest : itineraryRequest.slice(0, 1);

  useEffect(() => {
    if (isDebug) {
      dispatch(setOrchestra(isOrchestra));
      dispatch(setAmadeus(isAmadeus));
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <BoxSpaceBetween>
        <SelectHome
          control={control}
          name={'direction'}
          error={errors?.direction}
          options={filteredDirectionOptions}
          onChangeSelect={onDirectionChange}
        />
        <SelectHome control={control} name={'cabin'} error={errors?.cabin} options={ticketClassOptions} />
      </BoxSpaceBetween>
      {itineraries.map((leg, index) => {
        const departureDate = getValues(`ItineraryRequest.${index}.departureDate`);
        const returnDate = getValues(`ItineraryRequest.${index}.returnDate`);
        const departureAirport = getValues(`ItineraryRequest.${index}.originAirport.airportCode`);
        const arrivalAirport = getValues(`ItineraryRequest.${index}.destinationAirport.airportCode`);

        const dateInputFormattedValue = getFormattedDateSelection(departureDate, returnDate);
        return (
          <React.Fragment key={leg.id}>
            <Box sx={{ position: 'relative' }}>
              <MarginTop marginTop={index === 0 ? '10' : '30'}>
                <AutocompleteAirport
                  items={airportsData?.airports ?? []}
                  isLoading={loading || !airportsSearch}
                  error={errors?.ItineraryRequest?.[index]?.originAirport as FieldError}
                  control={control}
                  name={`ItineraryRequest.${index}.originAirport`}
                  inputLogo={<FlightTakeoffIcon />}
                  placeholderText="From"
                  onAirportSearch={onAirportSearch}
                />
              </MarginTop>
              <ChangeWayButton id={`${index}`} onClick={() => handleChangeWays(index)}>
                <CompareArrowsIcon id={`Flight-Compare-id${index}`} />
              </ChangeWayButton>
              <MarginTop marginTop="10">
                <AutocompleteAirport
                  items={airportsData?.airports ?? []}
                  isLoading={loading || !airportsSearch}
                  error={errors?.ItineraryRequest?.[index]?.destinationAirport as FieldError}
                  control={control}
                  name={`ItineraryRequest.${index}.destinationAirport`}
                  inputLogo={<FlightLandIcon />}
                  placeholderText="To"
                  onAirportSearch={onAirportSearch}
                />
              </MarginTop>
            </Box>
            <MarginTop marginTop="10">
              <SelectionDateBlock>
                <SelectionDates
                  returnDate={getValues(`ItineraryRequest.${index}.returnDate`)}
                  index={index}
                  setValue={setValue}
                  error={!!errors?.ItineraryRequest?.[index]?.departureDate?.message || !!errors?.ItineraryRequest?.[index]?.returnDate?.message}
                  inputValue={dateInputFormattedValue}
                  direction={direction}
                  control={control}
                  name={`ItineraryRequest.${index}.departureDate`}
                  clearErrors={clearErrors}
                  departureAirport={departureAirport}
                  arrivalAirport={arrivalAirport}
                />
                {isMultiCity && index > 0 && (
                  <IconButton>
                    <DeleteForeverIcon id={`${index}`} color="primary" fontSize="large" onClick={() => remove(index)} />
                  </IconButton>
                )}
              </SelectionDateBlock>
            </MarginTop>

            {isMultiCity && index + 1 === itineraryRequest.length && itineraryRequest.length < 3 && (
              <AddFlightBlock>
                <AddFlightButton
                  size={'large'}
                  disabled={itineraryRequest.length > 2}
                  onClick={addAnotherItinerary}
                  variant="outlined"
                  startIcon={<AddIcon />}
                >
                  Add another flight
                </AddFlightButton>
              </AddFlightBlock>
            )}
          </React.Fragment>
        );
      })}
      <MarginTop marginTop={'10'}>
        <PassengersCountDrawer handlePassengers={handlePassengers} />
      </MarginTop>
      <ButtonBlock>
        <Button id="Search Flight" data-testid="search-btn" fullWidth={true} variant={'contained'} size={'large'} type={'submit'}>
          Search
        </Button>
      </ButtonBlock>
    </form>
  );
};
