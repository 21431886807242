import { createTheme } from '@mui/material';
import { domains } from 'constants/domains';

interface Domains {
  default: string[];
  ke: string[];
}

interface DomainColors {
  primary: string;
  accent: string;
  primaryDark: string;
  primaryText: string;
  primaryBg: string;
}

const domainColors: Record<keyof Domains, DomainColors> = {
  default: {
    primary: 'var(--primaryFX)',
    accent: 'var(--accentFX)',
    primaryDark: 'var(--primaryDarkFX)',
    primaryText: 'var(--primaryTextFX)',
    primaryBg: 'var(--primaryBGFX)',
  },
  ke: {
    primary: 'var(--primaryKE)',
    accent: 'var(--accentKE)',
    primaryDark: 'var(--primaryDarkKE)',
    primaryText: 'var(--primaryTextKE)',
    primaryBg: 'var(--primaryBGKE)',
  },
};

export const setColorsBasedOnUrl = () => {
  const url = window.location.hostname;

  const rootStyles = getComputedStyle(document.documentElement);
  let primaryColor = rootStyles.getPropertyValue('--primary').trim();
  let primaryDarkColor = rootStyles.getPropertyValue('--primaryDark').trim();
  let primaryText = rootStyles.getPropertyValue('--primaryText').trim();
  let accentColor = rootStyles.getPropertyValue('--accent').trim();
  let primaryBg = rootStyles.getPropertyValue('--primaryBG').trim();

  Object.keys(domains).forEach((key) => {
    const domainKey = key as keyof Domains;

    domains[domainKey].forEach((domain) => {
      const regex = new RegExp(domain.replace('*.', '.*'));
      if (regex.test(url)) {
        primaryColor = domainColors[domainKey].primary ?? domainColors['default'].primary;
        primaryDarkColor = domainColors[domainKey].primaryDark ?? domainColors['default'].primaryDark;
        primaryText = domainColors[domainKey].primaryText ?? domainColors['default'].primaryText;
        accentColor = domainColors[domainKey].accent ?? domainColors['default'].accent;
        primaryBg = domainColors[domainKey].primaryBg ?? domainColors['default'].primaryBg;
      }
    });
  });

  // Apply colors to CSS variables
  document.documentElement.style.setProperty('--primary', primaryColor);
  document.documentElement.style.setProperty('--primaryDark', primaryDarkColor);
  document.documentElement.style.setProperty('--primaryText', primaryText);
  document.documentElement.style.setProperty('--accent', accentColor);
  document.documentElement.style.setProperty('--primaryBG', primaryBg);
};

export const getTheme = () => {
  const getCSSVariableValue = (variableName: string) => {
    const rootStyles = getComputedStyle(document.documentElement);
    return rootStyles.getPropertyValue(variableName).trim();
  };

  const primaryColor = getCSSVariableValue('--primary' ?? '--primaryFX');
  const accentColor = getCSSVariableValue('--accent' ?? '--accentFX');

  return createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: accentColor,
        dark: '#000000',
      },
    },
  });
};
