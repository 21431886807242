import React from 'react';
import { Header, Typography } from 'components';
import { ContainerStyled } from 'style';
import { Summary } from 'components/pages/summary';

export const SummaryPage = () => {
  const headerContent = (
    <Typography variant="titleBold" color="accent">
      Order Summary
    </Typography>
  );
  return (
    <>
      <Header content={headerContent} />
      <ContainerStyled>
        <Summary />
      </ContainerStyled>
    </>
  );
};
