import React, { useEffect } from 'react';
import { MarginTop, Typography } from 'components';
import { useWindowSize } from 'hooks/useWindowWidth';
import { TimerContainer, TypographyTimer } from './styles';

type PropTypes = {
  seconds: number;
  setSeconds: (seconds: number) => void;
};

export const Timer = ({ seconds, setSeconds }: PropTypes) => {
  const windowSize = useWindowSize();

  const { height, width } = windowSize;
  const isSmallHeight = height <= 720;
  const isSmallWidth = width <= 480;

  const startTimer = () => {
    setSeconds(seconds - 1);
  };

  useEffect(() => {
    const setTimeoutId = setTimeout(startTimer, 1000);
    if (seconds < 0) {
      clearTimeout(setTimeoutId);
    }
    return () => {
      clearTimeout(setTimeoutId);
    };
  });

  return (
    <TimerContainer>
      <MarginTop marginTop={isSmallHeight ? '12' : '36'}>
        <TypographyTimer sx={{ fontSize: isSmallWidth ? '96px' : '120px', justifyContent: 'center', alignItems: 'center' }}>
          00:{seconds < 10 ? 0 : ''}
          {seconds}
        </TypographyTimer>

        <MarginTop marginTop={isSmallHeight ? '12' : '36'}>
          <Typography variant="titleBold" color="primary">
            Please approve payment by entering PIN in your Mobile Money account
          </Typography>
        </MarginTop>
      </MarginTop>
    </TimerContainer>
  );
};
