import { styled } from '@mui/material';

export const BlogWrapper = styled('div')(() => ({
  p: {
    margin: '10px 0',
    lineHeight: '1.5rem',
  },
}));

export const PostDate = styled('div')(() => ({
  color: 'var(--gray60)',
  display: 'flex',
  justifyContent: 'flex-end',
}));
