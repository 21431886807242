import React from 'react';
import { JourneyOfferModel } from 'models/journeyOption.model';
import { ItineraryDetailed } from 'components/ItinerariesDetailed/ItineraryDetailed';
import { AccentDivider, ItineraryDetailedList } from './styles';

interface ItinerariesDetailedProps {
  journeyOffer: JourneyOfferModel;
  price: string;
  cabin: string;
  hasFooterButton: boolean;
  onFooterSubmit?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const ItinerariesDetailed = ({ journeyOffer, price, cabin, hasFooterButton, onFooterSubmit }: ItinerariesDetailedProps) => {
  const { itineraries, baggageAllowance } = journeyOffer;

  return (
    <ItineraryDetailedList>
      {itineraries.map((itinerary, index) => {
        return (
          <>
            <ItineraryDetailed
              key={itinerary.id}
              segments={itinerary.segments}
              price={price}
              changeOfDate={itinerary.changeOfDate}
              cabin={cabin}
              hasFooterButton={hasFooterButton}
              hasFooter={index === itineraries.length - 1}
              onFooterSubmit={onFooterSubmit}
              baggageAllowance={baggageAllowance}
            />
            {!index && <AccentDivider />}
          </>
        );
      })}
    </ItineraryDetailedList>
  );
};
