import React, { useEffect, useMemo, useState } from 'react';
import { routes } from 'routes';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

import { Loader } from 'components';
import { useAppSelector } from 'hooks/useAppSelector';
import { Currency, PaymentMode, PaymentNetwork, PaymentStatusEnum } from 'enum/enum';
import { CREATE_PAYMENT, CREATE_PAYMENT_data, CREATE_PAYMENT_vars } from 'api/mutations/createPayment';
import { setPaymentBillingInfo, setPaymentNumber, setPaymentTab } from 'redux/slices/payment/payment-slice';
import { PriceModel } from 'models/price.model';
import { getAmountCurrency } from 'utils/currency';
import { payments, successfulPaymentStatuses } from 'constants/payments';

import { EnterPaymentForm } from './forms/EnterPaymentForm';
import { ChargeBoard } from './ChargeBoard/ChargeBoard';
import { PaymentTabs } from './PaymentTabs/PaymentTabs';
import { CompanyPictures } from './CompanyPictures/CompanyPictures';
import { removePlusFromNumber } from 'utils/strings';
import { PaymentOption } from 'models/payment.model';

export const PaymentEntry = () => {
  const params = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const values = useAppSelector((state) => state.search);
  const flightOrderId = useAppSelector((state) => state.flightOrder.flightOrderId);
  const flightOrder = useAppSelector((state) => state.flightOrder);
  const [createIntentPayment, { loading }] = useMutation<CREATE_PAYMENT_data, CREATE_PAYMENT_vars>(CREATE_PAYMENT);

  const activeTab = useMemo(() => {
    const splitPath = params.pathname.split('/');
    const network = splitPath[splitPath.length - 1].toLowerCase();
    return payments.find((tab) => tab.network.toLowerCase() === network) ?? payments[0];
  }, []);

  const [activePaymentTab, setActivePaymentTab] = useState<PaymentOption>(activeTab);
  const [contactNumber, setContactNumber] = useState<string>('');

  const priceCurrencyData = activePaymentTab.network === PaymentNetwork.M_PESA_EXPRESS ? flightOrder?.priceOrderKes : flightOrder?.priceOrderUsd;
  const priceToDisplay = getAmountCurrency(priceCurrencyData as PriceModel);

  const initialPaymentRequest = async () => {
    const paymentData = await createIntentPayment({
      variables: {
        flightOrderId: flightOrderId as string,
        paymentMode: PaymentMode.STRIPE,
        valueInput: {
          amount: priceCurrencyData?.amount || '',
          currency: priceCurrencyData?.currency ?? Currency.USD,
        },
        accountNumber: removePlusFromNumber(contactNumber),
      },
    });
    if (paymentData.data?.createPayment) {
      dispatch(setPaymentBillingInfo(paymentData.data.createPayment));
    }
  };

  const onSubmit = async () => {
    dispatch(setPaymentTab(activePaymentTab));
    const paymentData = await createIntentPayment({
      variables: {
        flightOrderId: flightOrderId as string,
        valueInput: {
          amount: priceCurrencyData?.amount || '',
          currency: priceCurrencyData?.currency ?? Currency.USD,
        },
        paymentMode: activePaymentTab.paymentMode,
        ...(activePaymentTab.paymentMode !== PaymentMode.STRIPE && { accountNumber: removePlusFromNumber(contactNumber) }),
      },
    });
    dispatch(setPaymentNumber(removePlusFromNumber(contactNumber)));
    dispatch(setPaymentBillingInfo(paymentData?.data?.createPayment));
    const paymentState = paymentData.data?.createPayment.paymentState;
    if (successfulPaymentStatuses.includes(paymentState as PaymentStatusEnum) || paymentState === PaymentStatusEnum.OPEN) {
      history.push(routes.approval, { access: true });
    }
  };

  const onTabChange = (tab: PaymentOption) => {
    const newUrl = `/entry/${tab.network.toLowerCase()}`;
    history.replace(newUrl);
    setActivePaymentTab(tab);
  };

  useEffect(() => {
    initialPaymentRequest();
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      <ChargeBoard priceTotal={priceToDisplay} values={values} />
      <PaymentTabs tabs={payments} active={activePaymentTab} setActive={onTabChange} disabled={loading} />
      <EnterPaymentForm
        network={activePaymentTab.network}
        paymentLogo={activePaymentTab.img}
        onSubmit={onSubmit}
        setContactNumber={setContactNumber}
        disabled={loading}
      />
      <CompanyPictures />
    </>
  );
};
