import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { Header, Typography } from 'components';
import { FlightOptions } from 'components/pages/options/FlightOptions';
import { ContainerStyled } from 'style';
import { ArrowRightIcon } from 'assets/icons/ArrowRightIcon';
import { DirectionEnum } from 'enum/enum';
import { convertShortDate } from 'utils/dates';
import { countObjItems } from 'utils/math';
import { routes } from 'routes';
import { useAppSelector } from 'hooks/useAppSelector';
import { HeaderContainer, HeaderFlightDetailed, Passengers, Row } from './styles';

interface SearchParams {
  departureDate: string;
  destination: string;
  origin: string;
  returnDate: string;
  type: string;
}

export const FlightOptionsPage = () => {
  const reduxValues = useAppSelector((state) => state.search);
  const { ItineraryRequest, direction, passengers } = reduxValues;

  const isRound = direction === DirectionEnum.ROUND;
  const history = useHistory();
  const params = useParams<SearchParams>();
  const passengersCount = countObjItems(passengers);
  const dateSingleFrom = convertShortDate(params.departureDate || ItineraryRequest[0]?.departureDate);
  const dateRangeTo = isRound && convertShortDate(params.returnDate || ItineraryRequest[1]?.departureDate);

  const goBackHandler = () => {
    return history.push(routes.home);
  };

  const headerContent = (
    <HeaderContainer>
      <HeaderFlightDetailed>
        <Row>
          <Typography variant="body">{params.origin || ItineraryRequest[0]?.originAirport?.airportCode}</Typography>
          <ArrowRightIcon />
          <Typography variant="body">{params.destination || ItineraryRequest[0]?.destinationAirport?.airportCode}</Typography>
        </Row>

        <Row>
          <Typography variant="subtitle">{dateSingleFrom}</Typography>
          {isRound && (
            <>
              <Typography variant="body"> - </Typography>
              <Typography variant="subtitle">{dateRangeTo}</Typography>
            </>
          )}
        </Row>
      </HeaderFlightDetailed>

      <Passengers>
        <PersonIcon />
        {passengersCount}
      </Passengers>

      <IconButton onClick={goBackHandler} sx={{ color: 'var(--white)' }}>
        <EditIcon />
      </IconButton>
    </HeaderContainer>
  );
  return (
    <>
      <Header content={headerContent} />
      <ContainerStyled>
        <FlightOptions />
      </ContainerStyled>
    </>
  );
};
