import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloProvider } from '@apollo/client';

import { persistor } from 'redux/store';
import { App } from 'pages/App';
import { runApolloClientConfig } from 'config/config';
import { useAppSelector } from 'hooks/useAppSelector';

export const AppWithApollo = () => {
  const { isProductionMode } = useAppSelector((state) => state.debugger);
  const token = useAppSelector((state) => state.authSlice.token);
  const refCode = useAppSelector((state) => state.meta.refCode);

  const client = runApolloClientConfig(isProductionMode, token, refCode);

  return (
    <ApolloProvider client={client}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </ApolloProvider>
  );
};
