import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button, InputAdornment } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { ButtonBlock, JustifyCenter } from 'style';
import { Input, InputPhone, MarginTop, Typography } from 'components';
import { PaymentNetwork } from 'enum/enum';

import { schema } from './schema';
import { InputImageAdornment } from './styles';

interface FormValues {
  phoneNumber: string;
}

type PropTypes = {
  onSubmitHandler: () => void;
  setContactNumber: (contactNumber: string) => void;
  disabled: boolean;
  network: PaymentNetwork;
  paymentLogo?: string;
};
export const MobilePaymentForm = ({ paymentLogo, onSubmitHandler, setContactNumber, disabled, network }: PropTypes) => {
  const isVoucher = network === PaymentNetwork.VOUCHER;
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(schema(network)),
  });

  const phoneNumber = useWatch({
    control,
    name: 'phoneNumber',
    defaultValue: '',
  });

  useEffect(() => {
    setContactNumber(phoneNumber || '');
  }, [phoneNumber]);

  const onSubmit = (values: { phoneNumber: string }) => {
    setContactNumber(values.phoneNumber);
    onSubmitHandler();
  };

  return (
    <MarginTop marginTop={'30'}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
        <Typography variant="body">Numberka aad ka so diray so</Typography>
        <MarginTop marginTop="10">
          {isVoucher ? (
            <Input
              label={'Voucher'}
              control={control}
              name={`phoneNumber`}
              error={errors?.phoneNumber}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" sx={{ p: 0 }}>
                    <InputImageAdornment src={paymentLogo} alt="payment" />
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <InputPhone
              label={'Phone Number'}
              error={errors?.phoneNumber}
              control={control}
              name={`phoneNumber`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" sx={{ p: 0 }}>
                    <InputImageAdornment src={paymentLogo} alt="payment" />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </MarginTop>
        <ButtonBlock>
          <JustifyCenter>
            <Button type="submit" variant="contained" size="large" fullWidth disabled={disabled}>
              SEND CODE
            </Button>
          </JustifyCenter>
        </ButtonBlock>
      </form>
    </MarginTop>
  );
};
