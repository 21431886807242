import { gql } from '@apollo/client';
import { AirportInfoModel } from 'models/airport.model';

export interface AIRPORTS_data {
  airports: AirportInfoModel[];
}

export interface AIRPORTS_vars {
  search: string;
  threshold: number;
}

export const AIRPORTS = gql`
  query airports($search: String, $threshold: Float) {
    airports(search: $search, threshold: $threshold) {
      name
      city
      cityCode
      airportCode
      country
      countryCode
      travelerScore
      latitude
      longitude
      tzOffset
    }
  }
`;
