import { Paper, styled } from '@mui/material';

export const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--white);
  gap: 6px;
  width: 100%;
  padding: 12px;
`;
