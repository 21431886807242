import React from 'react';
import { Box, Typography, Button, styled } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Theme } from '@mui/material/styles';

type ChildrenReactTypeElement = {
  component: React.ReactNode;
  theme?: Theme;
};
export const StyledBoxMain = styled(Box)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const StyledBoxContainer = styled(Box)`
  width: 100%;
  margin: 0 auto;
  padding: 0;
  max-width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    max-width: 1236px;
  }
`;

export const StyledBoxRow = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    flex-direction: row;
  }
`;

export const StyledBoxInfo = styled(Box)`
  width: 100%;
  order: 2;
  display: flex;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    order: 1;
  }
`;

export const StyledBoxColInner = styled(Box)`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(4)};
  padding-bottom: ${({ theme }) => theme.spacing(8)};

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    max-width: 720px;
    padding-top: ${({ theme }) => theme.spacing(6)};
    padding-bottom: ${({ theme }) => theme.spacing(6)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    max-width: 1236px;
    padding-top: ${({ theme }) => theme.spacing(8)};
    padding-bottom: ${({ theme }) => theme.spacing(8)};
  }
`;

export const StyledTypographyTitle = styled(Typography)`
  font-weight: 700;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    text-align: left;
  }
`;

export const StyledTypographyDescription = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    text-align: left;
  }
`;
export const StyledBoxButtonWrap = styled(Box)`
  margin-top: 32px;
  display: flex;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
    justify-content: flex-start;
  }
`;
export const StyledLinkHome = styled(Button)<ChildrenReactTypeElement>`
  && {
    background-color: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.primary.contrastText};
    font-size: 15px;
    padding: 8px 22px;
  }
`;

export const StyledBoxImageWrapper = styled(Box)`
  flex: 0 0 100%;
  position: relative;
  max-width: 100%;
  order: 1;
  min-height: auto;

  @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
    flex: 0 0 50%;
    max-width: 50%;
    order: 2;
    min-height: calc(100vh - 218px);
  }
`;

export const StyledBoxImageInner = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 50vw;
  }
`;

export const StyledBoxImageCol = styled(Box)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const StyledBoxImageShapeContainer = styled(Box)`
  overflow: hidden;
  left: 0%;
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
    position: absolute;
    clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
    shape-outside: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
  }
`;

export const StyledBoxImageInnerContainer = styled(Box)`
  height: auto;

  & img {
    object-fit: cover;
  }

  & .lazy-load-image-loaded {
    height: 100%;
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
    height: 100%;
  }
`;

export const StyledLazyLoadImage = styled(LazyLoadImage)<ChildrenReactTypeElement>`
  height: auto;
  max-height: 300px;
  width: 100%;
  max-width: 100%;
  filter: ${(props) => (props.theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none')};

  @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
    height: 100%;
    max-height: unset;
  }
`;
