import React, { useMemo } from 'react';
import { Typography as TypographyMui, TypographyProps } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface TextProps extends Omit<TypographyProps, 'variant'> {
  children: React.ReactNode;
  variant?:
    | 'title'
    | 'titleBold'
    | 'smallTitle'
    | 'subtitle'
    | 'subtitleBold'
    | 'smallTitleBold'
    | 'tiny'
    | 'sectionTitle'
    | 'body'
    | 'bodyBold'
    | 'smallBody'
    | 'adaptiveText'
    | 'adaptiveBoldText'
    | 'lineHeightLarge';
  color?: string;
}

export const Typography = ({ variant = 'body', children, color, ...rest }: TextProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const variantStyles = useMemo(() => {
    return {
      lineHeightLarge: { fontSize: '24px', lineHeight: '36px' },
      title: { fontSize: '22px', lineHeight: '24px' },
      titleBold: { fontSize: '22px', lineHeight: '24px', fontWeight: 500 },
      smallTitleBold: { fontSize: '18px', lineHeight: '24px', fontWeight: 500 },
      smallTitle: { fontSize: '18px', lineHeight: '24px' },
      sectionTitle: { fontSize: '18px', lineHeight: '21px', fontWeight: 300 },
      body: { fontSize: '16px', lineHeight: '24px' },
      bodyBold: { fontSize: '16px', lineHeight: '24px', fontWeight: 500 },
      subtitle: { fontSize: '14px', lineHeight: '24px', fontWeight: 300 },
      subtitleBold: { fontSize: '14px', lineHeight: '24px', fontWeight: 500 },
      smallBody: { fontSize: '12px', lineHeight: '18px' },
      adaptiveText: { fontSize: isMobile ? '14px' : '16px', lineHeight: '24px' },
      adaptiveBoldText: { fontSize: isMobile ? '14px' : '16px', lineHeight: '24px', fontWeight: 600 },
      tiny: { fontSize: '10px', lineHeight: '24px' },
    };
  }, [variant, isMobile]);

  const typographyStyle = useMemo(() => {
    const baseStyle = variantStyles[variant];
    return color ? { ...baseStyle, color: `var(--${color})` } : baseStyle;
  }, [variantStyles, variant, color]);

  return (
    <TypographyMui {...rest} component="span" {...typographyStyle}>
      {children}
    </TypographyMui>
  );
};
