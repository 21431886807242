import React from 'react';

import { ImageWithLoader, Typography } from 'components';
import { SegmentModel } from 'models/segment.model';
import { convertShortDate, getDaysDifference } from 'utils/dates';
import { multipleOrSingleWordEnding } from 'utils/strings';

import { SegmentDetailedWrapper, SegmentTitle, Title, TitleImageSection, TitleSection } from './styles';
import { SegmentDetailedList } from './SegmentDetailedList';
import { BaggageAllowanceModel } from 'models/baggage.model';
import { images } from 'constants/images';

interface SegmentDetailedProps {
  segments: SegmentModel[];
  changeOfDate: number;
  baggageAllowance?: BaggageAllowanceModel | null;
}

export const SegmentDetailed = ({ segments, baggageAllowance, changeOfDate }: SegmentDetailedProps) => {
  const calculateStopsQuantity = (numStops: number) => (numStops ? ` ${numStops} ${multipleOrSingleWordEnding('stop', numStops > 1)}` : '');
  const departure = segments[0];
  const arrival = segments[segments.length - 1];
  const departureLogo = images.airline.getAirlineLogo(departure.marketingAirline.code);
  const stopsQuantity = calculateStopsQuantity(segments.length - 1);
  const isSameDates = convertShortDate(departure.departureAt) === convertShortDate(arrival.departureAt);

  const renderTitle = () => {
    const airportCodes = `${departure.originAirport.code} - ${arrival.destinationAirport.code}`;
    const departureDate = convertShortDate(departure.departureAt);
    const arrivalDate = getDaysDifference(departure.departureAt, changeOfDate, 'MMM DD');
    const travelDates = `${departureDate}${isSameDates ? '' : ` - ${arrivalDate}`}`;

    return (
      <SegmentTitle>
        <span>{airportCodes}</span>
        <span>{travelDates}</span>
        <span>{stopsQuantity}</span>
      </SegmentTitle>
    );
  };

  return (
    <SegmentDetailedWrapper>
      <TitleImageSection>
        <ImageWithLoader maxWidth={35} src={departureLogo} />
        <TitleSection>
          <Title>
            <Typography variant="body">{renderTitle()}</Typography>
          </Title>
        </TitleSection>
      </TitleImageSection>
      <SegmentDetailedList segments={segments} baggageAllowance={baggageAllowance} startDepartureDate={departure.departureAt} />
    </SegmentDetailedWrapper>
  );
};
