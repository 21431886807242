import React from 'react';
import { Header, Typography } from 'components';
import { HelpPageContent } from 'components/pages/help';

export const HelpPage = () => {
  const headerContent = (
    <Typography variant="titleBold" color="accent">
      FAQ & Help
    </Typography>
  );
  return (
    <>
      <Header content={headerContent} />
      <HelpPageContent />
    </>
  );
};
