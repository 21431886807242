import React from 'react';
import { Header, Typography } from 'components';
import { PaymentEntry } from 'components/pages/entry/PaymentEntry';
import { ContainerStyled } from 'style';

export const PaymentEntryPage = () => {
  const headerContent = (
    <Typography variant="titleBold" color="accent">
      Payment Entry
    </Typography>
  );

  return (
    <>
      <Header content={headerContent} />
      <ContainerStyled>
        <PaymentEntry />
      </ContainerStyled>
    </>
  );
};
