import { Button, styled } from '@mui/material';

export const ChangeWayButton = styled(Button)`
  position: absolute;
  right: 12px;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
  width: 48px;
  min-width: unset;
  height: 48px;
  background-color: var(--gray20) !important;
  border-radius: 10px;
  border: 5px solid var(--white);
  padding: 0;

  & svg {
    fill: var(--black);
    transform: rotate(90deg);
  }
`;

export const SelectionDateBlock = styled('div')`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
`;

export const AddFlightBlock = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const AddFlightButton = styled(Button)`
  align-self: flex-end;
`;
