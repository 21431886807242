import React, { useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { Header, ManualSelect, Typography } from 'components';
import { ContainerStyled } from 'style';
import { FlightSearchForm } from 'components/pages/home/FlightSearchForm';
import { currencyOptions } from 'constants/payments';
import { Currency } from 'enum/enum';
import { useAppSelector } from 'hooks/useAppSelector';
import { useClearingData } from 'hooks/useClearingData';
import { HeaderWrapper } from './styles';

export const isMpesaAvailable = JSON.parse(process.env.REACT_APP_MPESA_PAYMENT_AVAILABLE ?? 'false');
export const isCurrencyAvailable = JSON.parse(process.env.REACT_APP_CURRENCY_AVAILABLE ?? 'false');

export const Home = () => {
  useClearingData();

  const preferredCurrency = useAppSelector((state) => state.payment.currency);
  const [currency, setCurrency] = useState<Currency>(preferredCurrency);

  const onChangeCurrency = (e: SelectChangeEvent<unknown>) => {
    const newCurrency = e.target.value;
    setCurrency(newCurrency as Currency);
  };

  const headerContent = (
    <HeaderWrapper>
      <Typography variant="titleBold" color="accent">
        Flight Search
      </Typography>
      {isCurrencyAvailable && (
        <ManualSelect
          name="currency"
          options={isMpesaAvailable ? currencyOptions : currencyOptions.slice(0, 1)}
          value={currency}
          onChange={onChangeCurrency}
        />
      )}
    </HeaderWrapper>
  );

  return (
    <>
      <Header content={headerContent} />
      <ContainerStyled>
        <FlightSearchForm selectedCurrency={currency} />
      </ContainerStyled>
    </>
  );
};
