import { styled, Typography } from '@mui/material';
import { breakpoints } from 'style';

export const MyTripWrapper = styled('div')`
  display: flex;
  gap: 24px;
  margin: -24px 0;
  align-items: center;
  justify-content: center;
  min-height: inherit;
  & div {
    flex: 1;
  }
`;

export const ImageWrapper = styled('div')`
  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const CheckTripText = styled(Typography)(() => ({
  textTransform: 'uppercase',
  fontWeight: 700,
  color: 'var(--gray80)',
}));

export const WelcomeText = styled(Typography)(() => ({
  fontWeight: 700,
}));
WelcomeText.defaultProps = {
  variant: 'h4',
};
