import React, { useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import {
  AboutPage,
  BlogPage,
  BookFlightPage,
  FlightOptionsPage,
  HelpPage,
  Home,
  LoginPage,
  MyTripsPage,
  OrderSummaryPage,
  PageNotFound,
  PaymentApprovalPage,
  PaymentEntryPage,
  PaymentOptionsPage,
  PaymentSuccessfulPage,
  PrivacyTerms,
  ServicesPage,
  SummaryPage,
} from 'pages';
import { ChatwootBubble, CheckRefCode, Footer, Toaster, TTLModal } from 'components';
import ReactGA from 'react-ga';
import { routes } from 'routes';
import { runAppConfig } from 'config/config';
import { ProtectedRoute } from 'routes/protectedRoute';
import { ProtectedRouteWithData } from 'routes/protectedRouteWithData';
import { useAppSelector } from 'hooks/useAppSelector';
import { ChangeTicketPriceModal } from 'components/ChangeTicketPriceModal';
import { getTheme, setColorsBasedOnUrl } from 'config/domain/theme';

runAppConfig();
setColorsBasedOnUrl();
export const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const {
    booking: hasBookingAccess,
    summary: hasSummaryAccess,
    paymentOptions: hasPaymentOptionsAccess,
    entry: hasEntryAccess,
    success: hasSuccessAccess,
    services: hasServicesAccess,
  } = useAppSelector((store) => store.accessibility);

  const { enableTTL } = useAppSelector((state) => state.debugger);

  return (
    <>
      <Toaster />
      <CssBaseline>
        <ThemeProvider theme={getTheme()}>
          <BrowserRouter>
            {enableTTL && <TTLModal />}
            <ChangeTicketPriceModal />
            <CheckRefCode />
            <Switch>
              <Route exact path={routes.home} component={Home} />
              <Route exact path={routes.options} component={FlightOptionsPage} />
              <ProtectedRouteWithData path={routes.booking} component={BookFlightPage} hasAccess={hasBookingAccess} />
              <ProtectedRouteWithData path={routes.summary} component={SummaryPage} hasAccess={hasSummaryAccess} />
              <ProtectedRouteWithData path={routes.services} component={ServicesPage} hasAccess={hasServicesAccess} />
              <ProtectedRouteWithData path={routes.paymentOptions} component={PaymentOptionsPage} hasAccess={hasPaymentOptionsAccess} />
              <ProtectedRouteWithData path={routes.entry} component={PaymentEntryPage} hasAccess={hasEntryAccess} />
              <ProtectedRoute path={routes.approval} component={PaymentApprovalPage} />
              <ProtectedRouteWithData path={routes.success} component={PaymentSuccessfulPage} hasAccess={hasSuccessAccess} />
              <ProtectedRouteWithData path={routes.order} component={OrderSummaryPage} hasAccess={hasSuccessAccess} />
              <Route path={routes.about} component={AboutPage} />
              <Route path={routes.privacyTerms} component={PrivacyTerms} />
              <Route path={routes.help} component={HelpPage} />
              <Route path={routes.mytrips} component={MyTripsPage} />
              <Route path={routes.blog} component={BlogPage} />
              <Route path={routes.login} component={LoginPage} />
              <Route path={routes['404']} component={PageNotFound} />
              <Redirect to={routes['404']} />
            </Switch>
          </BrowserRouter>
          <ChatwootBubble />
          <Footer />
        </ThemeProvider>
      </CssBaseline>
    </>
  );
};
