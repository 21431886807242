import { styled } from '@mui/material';
import { Typography } from 'components/kit/Typography';

export const TimerContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TypographyTimer = styled(Typography)`
  display: flex;
  justify-content: center;
  font-weight: 500;
  line-height: 120px;
`;
