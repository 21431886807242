import { FormHelperText } from '@mui/material';
import styled from '@emotion/styled';

export const Error = styled(FormHelperText)`
  color: var(--error);
  font-size: 12px;
  line-height: 16.6px;
  width: max-content;
  margin: 3px 14px 0;
`;
