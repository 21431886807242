import React from 'react';
import { PaperItemWrapper } from 'style';
import { MarginTop, Typography } from 'components';
import { CardBrandImage } from 'components/CardBrandImage';
import { useGetPaymentInfo } from 'hooks/useGetPaymentInfo';

import { Column, Row } from './styles';

export const PaymentBlock = () => {
  const { card, cardLast4, confirmationNumber, price, lastCardDigitsLabel } = useGetPaymentInfo();
  return (
    <PaperItemWrapper>
      <Row>
        <Column>
          <Typography variant="smallTitleBold" color="primary">
            Payment
          </Typography>
        </Column>
      </Row>

      <Row>
        <Column>
          <Typography variant="subtitleBold" color="gray60">
            {lastCardDigitsLabel}
          </Typography>
          <Typography variant="bodyBold">{cardLast4}</Typography>
        </Column>
        <Column>
          <CardBrandImage src={card.img} />
        </Column>
      </Row>

      <MarginTop marginTop={'22'}>
        <Row>
          <Column>
            <Typography variant="subtitleBold" color="gray60">
              Payment Confirmation
            </Typography>
            <Typography variant="bodyBold">{confirmationNumber}</Typography>
          </Column>
          <Column>
            <Typography variant="subtitleBold" color="gray60">
              Payment Total
            </Typography>
            <Typography variant="subtitleBold">{price}</Typography>
          </Column>
        </Row>
      </MarginTop>
    </PaperItemWrapper>
  );
};
