import { styled } from '@mui/material';
import Divider from '@mui/material/Divider';

export const ItineraryDetailedList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const AccentDivider = styled(Divider)`
  border-bottom-width: medium;
  border-color: var(--primary);
  opacity: 0.7;
  border-radius: 4px;
`;
AccentDivider.defaultProps = {
  orientation: 'horizontal',
  variant: 'fullWidth',
};
