import { Box, Paper, styled } from '@mui/material';

export const ChargeBordStyled = styled(Paper)`
  background-color: var(--primary);
  color: var(--white);
`;

export const BoardContent = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 14px 34px 8px;
`;

export const BoardInformation = styled(BoardContent)`
  border-top: 1px solid var(--white);
  padding: 14px 0 8px;
  display: flex;
  gap: 16px;
`;

export const Row = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const HeaderRow = styled(Row)`
  gap: 16px;
`;

export const Column = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
