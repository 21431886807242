import { Card, styled } from '@mui/material';
import { breakpoints } from 'style';
import Typography from '@mui/material/Typography';

export const FlightOptionCard = styled(Card)(() => ({
  width: '100%',
}));

export const FlightOptionContent = styled('div')(() => ({
  display: 'flex',
}));

export const ItinerariesListWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const FlightOptionFooterWrapper = styled('div')(() => ({
  borderTop: `1px solid var(--border)`,
  display: 'flex',
  padding: '12px',
  flexDirection: 'column',
}));

export const FlightOptionFooterInfo = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const OperationAirlines = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const OperatedAirlineText = styled(Typography)(() => ({
  color: 'var(--gray60)',
  fontSize: '14px',
}));

export const ButtonSection = styled('div')`
  display: flex;
  gap: 16px;
  margin-top: 8px;
  justify-content: flex-end;
  @media (max-width: ${breakpoints.sm}) {
    button {
      flex: 1;
    }
  }
`;

export const TotalSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const TypographyTotal = styled(Typography)`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  white-space: nowrap;
`;

export const TypographyTicketClass = styled(Typography)`
  font-size: 14px;
  line-height: 14px;
  color: var(--gray60);
`;

export const TotalSectionFooter = styled(TotalSection)`
  display: flex;
  flex-direction: row;
`;
