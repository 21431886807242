import { baseLogoUrl } from './baseUrl';

export const images = {
  payments: {
    amadeus: `${baseLogoUrl}/other-logos/amadeus_logo.png`,
    zaad: `${baseLogoUrl}/other-logos/zaad.jpg`,
    golis: `${baseLogoUrl}/other-logos/golis.png`,
    evcplus: `${baseLogoUrl}/other-logos/evcplus.png`,
    telesom: `${baseLogoUrl}/other-logos/telesom.png`,
    creditCards: `${baseLogoUrl}/other-logos/creditCards.png`,
    mPressaExpress: `${baseLogoUrl}/other-logos/m_pesa_express.png`,
    waafi: `${baseLogoUrl}/other-logos/waafi.png`,
    mpesa: `${baseLogoUrl}/other-logos/mpesa.png`,
    voucher: `${baseLogoUrl}/other-logos/voucher.png`,
    visa: `${baseLogoUrl}/other-logos/visa.png`,
    edahab: `${baseLogoUrl}/other-logos/edahab.png`,
    mastercard: `${baseLogoUrl}/other-logos/mastercard.png`,
    amex: `${baseLogoUrl}/other-logos/amex.png`,
    discover: `${baseLogoUrl}/other-logos/discover.png`,
    unionpay: `${baseLogoUrl}/other-logos/unionpay.png`,
    jcb: `${baseLogoUrl}/other-logos/jcb.png`,
    stripe: `${baseLogoUrl}/other-logos/stripe.png`,
    dinersclub: `${baseLogoUrl}/other-logos/dinersclub.png`,
  },
  partners: {
    I4F: `${baseLogoUrl}/logos/I4F.png`,
    FZS: `${baseLogoUrl}/logos/FZS.png`,
    FMA: `${baseLogoUrl}/logos/FMA.png`,
    FHL: `${baseLogoUrl}/logos/FHL.png`,
    F6X: `${baseLogoUrl}/logos/F6X.png`,
    FDA: `${baseLogoUrl}/logos/FDA.png`,
  },
  team: {
    ceo: `${baseLogoUrl}/images/hassan-profile.jpg`,
    cto: `${baseLogoUrl}/images/jason-profile.jpg`,
  },
  domains: {
    fasax: {
      header: `${baseLogoUrl}/fasax-logos/fasax-header-logo.png`,
      drawer: `${baseLogoUrl}/fasax-logos/fasax-menu-logo.png`,
      service: `${baseLogoUrl}/fasax-logos/fasax-travel-services.png`,
    },
    ke: {
      header: `${baseLogoUrl}/ke-logos/ke-header-logo.jpg`,
      drawer: `${baseLogoUrl}/ke-logos/ke-drawer-logo.png`,
      service: `${baseLogoUrl}/ke-logos/ke-drawer-logo.png`,
    },
  },
  airline: {
    getAirlineLogo: (code: string) => {
      return `${baseLogoUrl}/logos/${code}.png`;
    },
  },
};
