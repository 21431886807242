import { Box, Container, Paper, styled } from '@mui/material';
import { headerFooterHeight } from 'style/vars/constants';
import { breakpoints } from 'style/vars/breakpoints';

export const ContainerStyled = styled(Container)`
  min-height: calc(100vh - ${headerFooterHeight}px);
  padding: 24px;
  @media (max-width: ${breakpoints.sm}) {
    padding: 12px;
  }
`;

export const PaperWrapper = styled(Paper)`
  padding: 30px 32px 28px 32px;
`;

export const PaperItemWrapper = styled(Box)`
  padding: 26px 22px 16px 22px;
  border-top: 1px solid var(--gray10);
  @media (max-width: 380px) {
    padding: 20px 16px 12px 16px;
  }
`;

export const ButtonBlock = styled('div')`
  margin-top: 30px;
  padding: 0;
`;

export const BoxSpaceBetween = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-transform: capitalize;
`;

export const JustifyCenter = styled(Box)`
  display: flex;
  justify-content: center;
`;
