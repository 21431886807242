import { Box, Drawer, ListItemText, ListItem, styled } from '@mui/material';

export const DrawerStyled = styled(Drawer)`
  svg {
    fill: var(--primary);
  }
`;
export const LogoWrapper = styled(Box)`
  max-width: 160px;
  margin: 30px 0 30px 16px;
`;

export const ListTextItem = styled(ListItemText)`
  margin-left: 8px;
`;

export const ListHeaderItem = styled(ListItem)`
  background-color: var(--primaryBG);
  color: var(--primaryText);
  span {
    font-weight: 500;
  }
`;
